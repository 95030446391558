.App {
  text-align: center;
}

.App-logo {
  #animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #fff;
  height: 150px;
  padding: 20px;
  color: #16181b;
}

.App-title {
	font-size: 1.5em;
}

.App-intro {
	font-size: large;
}

.App-main {
	#padding-top: 35px;
}

.buttonRow {
	margin-top: 10px;
	#height: 30px;
}

select,
button {
	font-family: sans-serif;
	font-size: 14px;
	margin: 1px;
	height: 30px;
	color: #16181b;
	background: none;
	box-shadow: none;
	#border-radius: 5px;
	border-width: 1px;
	border-color: #16181b;
}

ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

li {
	display: inline;
}

.active,
button:hover {
	background-color: #111;
	color: #fff;
}

.jsonTable {
	margin-left: auto;
	margin-right: auto;
	overflow: auto;
}

.competitorsTable {
	margin-left: auto;
	margin-right: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*################ Mobile ##################*/
@media screen and (max-width: 680px) {

	.competitorsTable {
		width: 780px;
	}
}
